/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  NotificationCountResponse,
  NotificationRequest,
  NotificationResponse,
  NotificationRuleResponse,
  ResultResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getNotificationControllerAxios = ({
  type,
  transportServerType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  type: 'EMAIL' | 'SLACK' | 'SMS' | 'TELEGRAM' | 'WEBHOOK';
  transportServerType?: 'NCP' | 'SMS_DB' | 'SMTP';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<NotificationResponse> => {
  const localVarPath = '/api/v6/metav6/notification/get';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      transportServerType,
      type,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNotificationCountNotificationControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<NotificationCountResponse> => {
  const localVarPath = '/api/v6/metav6/notification/notification-count';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNotificationNotificationControllerAxios = ({
  type,
  transportServerType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  type: 'EMAIL' | 'SLACK' | 'SMS' | 'TELEGRAM' | 'WEBHOOK';
  transportServerType?: 'NCP' | 'SMS_DB' | 'SMTP';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<NotificationRuleResponse> => {
  const localVarPath = '/api/v6/metav6/notification/notification-get';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      transportServerType,
      type,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const activationNotificationControllerAxios = ({
  activation,
  methodId,
  type,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  activation: boolean;
  methodId: number;
  type: 'EMAIL' | 'SLACK' | 'SMS' | 'TELEGRAM' | 'WEBHOOK';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/metav6/{methodId}/activation'.replace(
    `{${'methodId'}}`,
    encodeURIComponent(String(methodId)),
  );
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      activation,
      type,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const deleteNotificationControllerAxios = ({
  methodIds,
  type,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  methodIds: Array<number>;
  type: 'EMAIL' | 'SLACK' | 'SMS' | 'TELEGRAM' | 'WEBHOOK';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v6/metav6/notification/delete';
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      methodIds,
      type,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateNotificationControllerAxios = ({
  methodId,
  type,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  methodId: number;
  type: 'EMAIL' | 'SLACK' | 'SMS' | 'TELEGRAM' | 'WEBHOOK';
  request: NotificationRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v6/metav6/notification/update';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      methodId,
      type,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addNotificationControllerAxios = ({
  type,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  type: 'EMAIL' | 'SLACK' | 'SMS' | 'TELEGRAM' | 'WEBHOOK';
  request: NotificationRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v6/metav6/notification/add';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      type,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getNotificationControllerAxios,
  getNotificationCountNotificationControllerAxios,
  getNotificationNotificationControllerAxios,
  activationNotificationControllerAxios,
  deleteNotificationControllerAxios,
  updateNotificationControllerAxios,
  addNotificationControllerAxios,
};
