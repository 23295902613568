/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApiResponseGroupUserOpenInfoResponse,
  ApiResponsePasswordValidationDto,
  ApiResponseUserOpenInfoResponse,
  ApiResponseboolean,
  ApiResponsestring,
  CheckPasswordV8Request,
  CheckPasswordValidationDto,
  UserV8Request,
  UserV8Response,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getAllGroupByUsersUserV8ControllerAxios = ({
  groupId,
  tenantId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  groupId: number;
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseGroupUserOpenInfoResponse> => {
  const localVarPath = '/api/v8/groups/{groupId}/open-info/all-users'.replace(
    `{${'groupId'}}`,
    encodeURIComponent(String(groupId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      tenantId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPasswordValidationRuleUserV8ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponsePasswordValidationDto> => {
  const localVarPath = '/api/v8/password-validation';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getByUserIdUserV8ControllerAxios = ({
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  userId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseUserOpenInfoResponse> => {
  const localVarPath = '/api/v8/users/open-info/{userId}'.replace(
    `{${'userId'}}`,
    encodeURIComponent(String(userId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateUserUserV8ControllerAxios = ({
  request,
  patchUserId,
  tenantId,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: UserV8Request;
  patchUserId?: number;
  tenantId?: string;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserV8Response> => {
  const localVarPath = '/api/v8/users';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      patchUserId,
      tenantId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const changePasswordUserV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: CheckPasswordV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponsestring> => {
  const localVarPath = '/api/v8/password-check';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addPasswordValidationRuleUserV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: CheckPasswordValidationDto;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseboolean> => {
  const localVarPath = '/api/v8/password-validation';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addUserUserV8ControllerAxios = ({
  request,
  tenantId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: UserV8Request;
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UserV8Response> => {
  const localVarPath = '/api/v8/users';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      tenantId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getAllGroupByUsersUserV8ControllerAxios,
  getPasswordValidationRuleUserV8ControllerAxios,
  getByUserIdUserV8ControllerAxios,
  updateUserUserV8ControllerAxios,
  changePasswordUserV8ControllerAxios,
  addPasswordValidationRuleUserV8ControllerAxios,
  addUserUserV8ControllerAxios,
};
