const BLUE_STATUS = [
  'ok',
  'running',
  'processing',
  'active',
  'using',
  'user',
  'valid',
  'public',
  'runnable',
];
const GREEN_STATUS = [
  'normal',
  'succeeded',
  'complete',
  'completion',
  'available',
  'ready',
  'connection',
  'connect',
  'up',
  'bound',
  'admin',
  'finished',
];
const YELLOW_STATUS = [
  'waiting',
  'pending',
  'warning',
  'suspended',
  'dormant',
  'not_present',
  'lower_layer_down',
  'notready',
  'stop',
];
const RED_STATUS = [
  'timed-waiting',
  'error',
  'exception',
  'critical',
  'terminated',
  'terminating',
  'down',
  'false',
  'failed',
  'failure-target',
  'abnormal',
  'timeout',
];
export const DISABLED_STATUS = ['blocked'];
// NOTE: For Test
export const GRAY_STATUS = [
  'none',
  'disabled',
  'no-signal',
  'inactive',
  'unknown',
  'no-data',
  'combination',
  'and',
  'or',
  'tag',
  'target',
  'off',
  'testing',
  'exited',
  'snoozing',
  'diskpressure',
  'pidpressure',
  'networkunavailable',
  'stopped',
  'stopping',
];
export const STATUS_SET = {
  BLUE: BLUE_STATUS,
  GREEN: GREEN_STATUS,
  YELLOW: YELLOW_STATUS,
  RED: RED_STATUS,
  DISABLED: DISABLED_STATUS,
};
