import { RouteRecordRaw } from 'vue-router';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { VIEW_MODE } from '@/common/define/common.define';

const PREFIX_INFRASTRUCTURE = 'infrastructure_';

const infrastructureMenu: RouteRecordRaw[] = [
  {
    path: '/infrastructure/',
    name: `${PREFIX_INFRASTRUCTURE}Infrastructure`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE,
    },
    children: [
      {
        path: 'host',
        name: `${PREFIX_INFRASTRUCTURE}Host`,
        component: () =>
          import(
            /* webpackChunkName: "infrastructure-host-view" */ '@/infrastructure/views/hostView/HostView.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_HOST,
        },
      },
      {
        path: 'container',
        name: `${PREFIX_INFRASTRUCTURE}Container`,
        component: () =>
          import(
            /* webpackChunkName: "infrastructure-container-view" */ '@/infrastructure/views/containerView/ContainerView.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_CONTAINER,
          invisibleViewMode: [VIEW_MODE.MAXGAUGE],
        },
      },
      {
        path: 'networkDevice',
        name: `${PREFIX_INFRASTRUCTURE}Network Device`,
        component: () =>
          import(
            /* webpackChunkName: "infrastructure-network-device-view" */ '@/infrastructure/views/networkDeviceView/NetworkDeviceView.vue'
          ),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_NETWORK_DEVICE,
          invisibleViewMode: [VIEW_MODE.MAXGAUGE],
        },
      },
      {
        path: 'npm',
        name: `${PREFIX_INFRASTRUCTURE}Network Performance Monitoring`,
        component: () => import('@/infrastructure/views/npm/NetworkPerformanceMonitoring.vue'),
        meta: {
          rolePermission:
            ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_NETWORK_PERFORMANCE_MONITORING,
          invisibleViewMode: [VIEW_MODE.MAXGAUGE],
        },
      },
      // {
      //   path: 'tool',
      //   name: `${PREFIX_INFRASTRUCTURE}Tool`,
      //   meta: {
      //     rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_TOOL,
      //     invisibleViewMode: [VIEW_MODE.MAXGAUGE],
      //   },
      //   children: [
      //     {
      //       path: 'script-manager',
      //       name: `${PREFIX_INFRASTRUCTURE}Script Manager`,
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "infrastructure-script-manager" */ '@/infrastructure/views/scriptManagerView/ScriptManagerView.vue'
      //         ),
      //       meta: {
      //         rolePermission:
      //           ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_TOOL_SCRIPT_MANAGER,
      //         invisibleViewMode: [VIEW_MODE.MAXGAUGE],
      //       },
      //     },
      //     {
      //       path: 'script-manager-history',
      //       name: `${PREFIX_INFRASTRUCTURE}Script Manager History`,
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "infrastructure-script-manager-history" */ '@/infrastructure/views/scriptManagerHistoryView/ScriptManagerHistoryView.vue'
      //         ),
      //       meta: {
      //         rolePermission:
      //           ROLE_PERMISSION_KEY.MONITORING
      //             .MONITORING_INFRASTRUCTURE_TOOL_SCRIPT_MANAGER_HISTORY,
      //         invisibleViewMode: [VIEW_MODE.MAXGAUGE],
      //       },
      //     },
      //   ],
      // },
    ],
  },
];

const getInfrastructureNaviMenu = () => {
  const filteredInfrastructureMenu = extractAccessibleRoutes(infrastructureMenu);

  return [...filteredInfrastructureMenu];
};

export { infrastructureMenu, getInfrastructureNaviMenu };
