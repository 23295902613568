import { i18n } from '@/common/locale';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { CUSTOM_PERIOD_PREFIX } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import { store } from '@/common/store';
import { TimezoneName } from '@/common/utils';
import {
  UTC_TIME_FORMAT,
  GLOBAL_TIME_RANGE_FORMAT,
  GLOBAL_TIME_LIVE_FORMAT,
  MIN_DATE_TIME,
} from './timePeriodIndicator.define';

dayjs.extend(customParseFormat);

export const getSafeDateTime = (time: Dayjs) => {
  const minDateTime = dayjs(MIN_DATE_TIME);
  return time.isValid() && time.utc().isAfter(minDateTime.utc()) ? time : minDateTime;
};
export const parseInterval = (period: string): { offset: number; unit: ManipulateType } => {
  if (period.split(' ').length > 1) {
    const [hours, minutes] = period.split(' ');
    return {
      offset: +hours.slice(1, -1) * 60 + +minutes.slice(0, -1),
      unit: minutes.slice(-1) as ManipulateType,
    };
  }

  return {
    offset: +period.slice(1, -1),
    unit: period.slice(-1) as ManipulateType,
  };
};

export const returnMatchingFormat = (time: string) => {
  if (time.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
    return UTC_TIME_FORMAT;
  }
  if (time.match(/^\d{2}.\d{2}.\d{2} \d{2}:\d{2}:\d{2}$/)) {
    return GLOBAL_TIME_RANGE_FORMAT;
  }
  if (time.match(/^\d{4}.\d{2}.\d{4} \d{2}:\d{2}:\d{2}$/)) {
    return GLOBAL_TIME_LIVE_FORMAT;
  }
  return undefined;
};

export const getDiffSecond = (fromTime, toTime): number => {
  const fromTimeParsed = dayjs(fromTime, GLOBAL_TIME_RANGE_FORMAT);
  const toTimeParsed = dayjs(toTime, GLOBAL_TIME_RANGE_FORMAT);

  return toTimeParsed.diff(fromTimeParsed, 's');
};

export const parsePeriod = (period: string): { offset: number; unit: 's' } => {
  const timeParts = period.split(' ');
  let offset = 0;

  timeParts.forEach((part) => {
    const value = parseInt(part.includes('p') ? part.slice(1) : part, 10);
    if (part.includes('d')) {
      offset += value * 60 * 60 * 24;
    } else if (part.includes('h')) {
      offset += value * 60 * 60;
    } else if (part.includes('m')) {
      offset += value * 60;
    } else if (part.includes('s')) {
      offset += value;
    } else if (part.includes('w')) {
      offset += value * 60 * 60 * 24 * 7;
    } else if (part.includes('M')) {
      const now = dayjs().tz();
      const targetDate = now.subtract(value, 'month');
      const diffInSeconds = now.diff(targetDate, 'second');
      offset += diffInSeconds;
    }
  });

  return {
    offset,
    unit: 's',
  };
};

export const getFormalPeriod = (period: string) =>
  period.includes(CUSTOM_PERIOD_PREFIX) ? period.slice(CUSTOM_PERIOD_PREFIX.length) : period;

export const isExceededCurrentTime = ({ time }: { time: string }) => {
  const { timezone: tz } = store.getters['myInfo/getAccountInfo'];

  return dayjs(time, returnMatchingFormat(time)).tz(tz, true).unix() > dayjs.tz().unix();
};

export const getForwardTimeRange = ({ fromTime, toTime }: { fromTime: string; toTime: string }) => {
  const offset = getDiffSecond(fromTime, toTime);
  const { timezone: tz } = store.getters['myInfo/getAccountInfo'];

  return {
    fromTime: dayjs(fromTime, returnMatchingFormat(fromTime))
      .tz(tz, true)
      .add(offset, 's')
      .format(GLOBAL_TIME_RANGE_FORMAT),
    toTime: dayjs(toTime, returnMatchingFormat(toTime))
      .tz(tz, true)
      .add(offset, 's')
      .format(GLOBAL_TIME_RANGE_FORMAT),
  };
};

export const getBackTimeRange = ({ fromTime, toTime }: { fromTime: string; toTime: string }) => {
  const offset = getDiffSecond(fromTime, toTime);
  const { timezone: tz } = store.getters['myInfo/getAccountInfo'];

  return {
    fromTime: dayjs(fromTime, returnMatchingFormat(fromTime))
      .tz(tz, true)
      .subtract(offset, 's')
      .format(GLOBAL_TIME_RANGE_FORMAT),
    toTime: dayjs(toTime, returnMatchingFormat(toTime))
      .tz(tz, true)
      .subtract(offset, 's')
      .format(GLOBAL_TIME_RANGE_FORMAT),
  };
};

export const getCurrentTimeRange = ({
  period,
  timeFormat = GLOBAL_TIME_RANGE_FORMAT,
  fixedTimes,
}: {
  period: string;
  timeFormat?: string;
  fixedTimes?: {
    hour?: number | undefined;
    minute?: number | undefined;
    second?: number | undefined;
  };
}) => {
  const { offset, unit } = parsePeriod(period);
  let currentTime = dayjs.tz();

  if (fixedTimes?.second !== undefined && fixedTimes.second > -1) {
    currentTime = currentTime.second(fixedTimes.second);
  }

  if (fixedTimes?.minute !== undefined && fixedTimes.minute > -1) {
    currentTime = currentTime.minute(fixedTimes.minute);
  }

  if (fixedTimes?.hour !== undefined && fixedTimes.hour > -1) {
    currentTime = currentTime.hour(fixedTimes.hour);
  }

  return {
    fromTime: getSafeDateTime(currentTime.subtract(offset, unit)).format(timeFormat),
    toTime: currentTime.format(timeFormat),
  };
};

export const formatTimeRange = ({
  fromTime,
  toTime,
  timeFormat = GLOBAL_TIME_RANGE_FORMAT,
}: {
  fromTime: string;
  toTime: string;
  timeFormat?: string;
}) => {
  return {
    fromTime: dayjs(fromTime).format(timeFormat),
    toTime: dayjs(toTime).format(timeFormat),
  };
};

export const formatTimeRangeToUtc = ({
  fromTime,
  toTime,
}: {
  fromTime: string;
  toTime: string;
}) => {
  const { timezone: tz } = store.getters['myInfo/getAccountInfo'];

  return {
    fromTimeUtc: dayjs(fromTime, returnMatchingFormat(fromTime))
      .tz(tz, true)
      .utc()
      .format(UTC_TIME_FORMAT),
    toTimeUtc: dayjs(toTime, returnMatchingFormat(toTime))
      .tz(tz, true)
      .utc()
      .format(UTC_TIME_FORMAT),
  };
};

/**
 * Formats the time range to the specified timezone.
 * @param {Object} options - The options for formatting the time range.
 * @param {string} options.fromTimeUtc - The starting time in UTC format.
 * @param {string} options.toTimeUtc - The ending time in UTC format.
 * @returns {Object} - The formatted time range in the specified timezone.
 */
export const formatTimeRangeToTz = ({
  fromTimeUtc,
  toTimeUtc,
  tz = store.getters['myInfo/getTimeZone'],
}: {
  fromTimeUtc: string;
  toTimeUtc: string;
  tz?: TimezoneName;
}) => {
  return {
    fromTime: dayjs(fromTimeUtc, returnMatchingFormat(fromTimeUtc))
      .utc(true)
      .tz(tz)
      .format(GLOBAL_TIME_RANGE_FORMAT),
    toTime: dayjs(toTimeUtc, returnMatchingFormat(fromTimeUtc))
      .utc(true)
      .tz(tz)
      .format(GLOBAL_TIME_RANGE_FORMAT),
  };
};

/**
 * Checks if the pause button is clicked based on the previous and new time ranges.
 * @param prevTimeRange - The previous time range.
 * @param newTimeRange - The new time range.
 * @returns A boolean indicating whether the pause button is clicked.
 */
export const isClickedPause = (prevTimeRange: TimePeriodInfo, newTimeRange: TimePeriodInfo) =>
  !prevTimeRange.isPaused &&
  prevTimeRange.isPaused !== newTimeRange.isPaused &&
  prevTimeRange.fromTime === newTimeRange.fromTime &&
  prevTimeRange.toTime === newTimeRange.toTime;

export const DEFAULT_PERIOD_ITEMS = [
  { name: 'Past 5 Minutes', value: 'p5m', shortName: '5m' },
  { name: 'Past 10 Minutes', value: 'p10m', shortName: '10m' },
  { name: 'Past 30 Minutes', value: 'p30m', shortName: '30m' },
  { name: 'Past 1 Hours', value: 'p1h', shortName: '1h' },
  { name: 'Past 3 Hours', value: 'p3h', shortName: '3h' },
  { name: 'Past 6 Hours', value: 'p6h', shortName: '6h' },
  { name: 'Past 12 Hours', value: 'p12h', shortName: '12h' },
  { name: 'Past 1 Day', value: 'p1d', shortName: '1d' },
] as const;

export const getInitPeriodInfoFromTimeRange = (time: {
  fromTime: string;
  toTime: string;
  tz?: TimezoneName;
  timePeriod?: string;
  isPaused?: boolean;
}): TimePeriodInfo => {
  return {
    isPaused: time.isPaused ?? false,
    timePeriod: time.timePeriod ?? 'p10m',
    ...time,
    ...formatTimeRangeToUtc(time),
  };
};

export const getTimePeriodByRange = (fromTimeString: string, toTimeString: string) => {
  const fromTime = +dayjs(fromTimeString);
  const toTime = +dayjs(toTimeString);

  let diff = toTime - fromTime;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);

  const hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  const minutes = Math.floor(diff / (1000 * 60));
  diff -= minutes * (1000 * 60);

  const seconds = Math.floor(diff / 1000);

  const daysString = days > 0 ? `${days}d ` : '';
  const hoursString = hours > 0 ? `${hours}h ` : '';
  const minutesString = minutes > 0 ? `${minutes}m ` : '';
  const secondsString = seconds > 0 ? `${seconds}s ` : '';

  return `p${daysString}${hoursString}${minutesString}${secondsString}`.trim();
};

export const getLocaleLabel = (value: string) => {
  const units = {
    s: 'SECOND',
    m: 'MINUTE',
    h: 'HOUR',
    d: 'DAY',
  };

  const match = value.match(/^p(\d+)([mhds])$/);
  if (match) {
    const [, num, unit] = match;
    const unitLocaleKey = `WORD.${units[unit]}${+num > 1 ? 'S' : ''}`;
    return `${i18n.global.t('WORD.PAST')} ${i18n.global.t(unitLocaleKey, { value: num })}`;
  }

  return value;
};
